import { h, render } from 'vue'
import TwUiToast from '@/components/ui/toast.vue'

export function useToast() {
  const nuxtApp = useNuxtApp();
  const showToast = (type: string, title: string, content: string, duration = 3000, position = 'top-center', teleportTarget = 'body') => {
    let isClosed = false;

    const toastProps = {
      type,
      title,
      content,
      duration,
      position,
      teleportTarget,
      closeToast: () => {
        if (!isClosed) {
          closeToast(toastElement);
          isClosed = true;
        }
      },
    }

    const toastVNode = h(TwUiToast, toastProps)
    toastVNode.appContext = nuxtApp.vueApp._context
    const toastElement = document.createElement('div')
    render(toastVNode, toastElement)
    document.body.appendChild(toastElement)

    setTimeout(() => {
      if (!isClosed) {
        closeToast(toastElement);
        isClosed = true;
      }
    }, duration)
  }

  const closeToast = (element: Element) => {
    render(null, element)
    if (element.parentNode === document.body) {
      document.body.removeChild(element)
    }
  }

  return { showToast }
}
